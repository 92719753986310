import request from '@/utils/request'


export function getProgrammezgw(query) {
    return request({
        url: '/www/programme-set/page',
        method: 'get',
        params: query
    })
}
export function getText(query) {
    return request({
        url: '/www/text-set/page',
        method: 'get',
        params: query
    })
}
export function getApplication(query) {
    return request({
        url: '/www/application-set/page',
        method: 'get',
        params: query
    })
}
export function getDHpage() {
    return request({
        url: '/www/diamond-banner/getHome',
        method: 'get',
    })
}

export function getProduct(query) {
    return request({
        url: '/www/product-set/page',
        method: 'get',
        params: query
    })
}

export function getProgramme(query) {
    return request({
        url: '/www/business-info/page',
        method: 'get',
        params: query
    })
}

export function getTrends(query) {
    return request({
        url: '/www/trends-set/page',
        method: 'get',
        params: query
    })
}


export function getTranslate(query) {
    return request({
        url: '/www/translate-set/page',
        method: 'get',
        params: query
    })
}


export function getCompany(query) {
    return request({
        url: '/www/company-set/page',
        method: 'get',
        params: query
    })
}
