import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		redirect: '/home',
		children: [
			{ path: '/home', name: '首页', component: () => import('../views/home/index.vue'), meta: { title: '首页' } },
			{ path: '/wisdomFactory', name: '智能工厂', component: () => import('../views/wisdomFactory/index.vue'), meta: { title: '智能工厂' } },
			{ path: '/intelligentSecurity', name: '智慧安防', component: () => import('../views/intelligentSecurity/index.vue'), meta: { title: '智慧安防' } },
			{ path: '/about', name: '关于天乾地坤', component: () => import('../views/about/index.vue'), meta: { title: '关于天乾地坤' } },
			{ path: '/wisdomPark', name: '智慧园区', component: () => import('../views/wisdomPark/index.vue'), meta: { title: '智慧园区' } },
			{ path: '/publicHose', name: '智慧公租房', component: () => import('../views/publicHose/index.vue'), meta: { title: '智慧公租房' } },
			{ path: '/wisdomProperty', name: '详情', component: () => import('../views/wisdomProperty/index.vue'), meta: { title: '详情' } },
			{ path: '/wisdomHotel', name: '智慧酒店', component: () => import('../views/wisdomHotel/index.vue'), meta: { title: '智慧酒店' } },
			{ path: '/wisdomFamily', name: '主营业务', component: () => import('../views/wisdomFamily/index.vue'), meta: { title: '主营业务' } },
			{ path: '/wisdomSportsPark', name: '智慧体育公园', component: () => import('../views/wisdomSportsPark/index.vue'), meta: { title: '智慧体育公园' } },
			{ path: '/wisdomPartySchool', name: '智慧党建', component: () => import('../views/wisdomPartySchool/index.vue'), meta: { title: '智慧党建' } },
			{ path: '/wisdomCampus', name: '软件研发服务', component: () => import('../views/wisdomCampus/index.vue'), meta: { title: '软件研发服务' } },
			{ path: '/energy', name: '解决方案', component: () => import('../views/energy/index.vue'), meta: { title: '解决方案' } },
			{ path: '/wisdomStreetLamp', name: '智慧路灯', component: () => import('../views/wisdomStreetLamp/index.vue'), meta: { title: '智慧路灯' } },
			{ path: '/wisdomNetwork', name: '智慧管廊', component: () => import('../views/wisdomNetwork/index.vue'), meta: { title: '智慧管廊' } },
			{ path: '/wisdomGreenhouses', name: '新闻动态', component: () => import('../views/wisdomGreenhouses/index.vue'), meta: { title: '新闻动态' } },
			{ path: '/littleDoorGods', name: '全要素疫情防控', component: () => import('../views/littleDoorGods/index.vue'), meta: { title: '全要素疫情防控' } },
			{ path: '/cooperation', name: '加盟合作', component: () => import('../views/cooperation/index.vue'), meta: { title: '加盟合作' } },
			{ path: '/mall', name: '产品中心', component: () => import('../views/mall/index.vue'), meta: { title: '产品中心' } },
			{ path: '/product', name: '商城', component: () => import('../views/product/index.vue'), meta: { title: '产品' } },
			{ path: '/littleDoorGodsDetails', name: '小门神详情', component: () => import('../views/littleDoorGodsDetails/index.vue'), meta: { title: '小门神详情' } },
			{ path: '/chamber', name: '核酸采样室', component: () => import('../views/chamber/index.vue'), meta: { title: '核酸采样室' } },
			{ path: '/thermometer', name: '业务详情', component: () => import('../views/thermometer/index.vue'), meta: { title: '业务详情' } },
			{ path: '/allInOne', name: '测温一体机', component: () => import('../views/allInOne/index.vue'), meta: { title: '测温一体机' } },
			{ path: '/successfulCases', name: '成功案例', component: () => import('../views/successfulCases/index.vue'), meta: { title: '成功案例' } }
		]
	},
	// {
	// path: '/about',
	// name: 'About',
	// route level code-splitting
	// this generates a separate chunk (about.[hash].js) for this route
	// which is lazy-loaded when the route is visited.
	// component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
	// }
]

const router = new VueRouter({
	routes

})

router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title
	} else {
		document.title = "天乾地坤官方网站"
	}
	next()
})
router.afterEach(() => {
	window.scrollTo(0, 0);
})
export default router
