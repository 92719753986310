<template>
	<div class="home">
		<el-container>
			<el-header>
				<img class="logo" src="../assets/imgs/logo.png" alt="">
				<div class="nav-div">
					<div class="nav">
						<span v-for="(item,index) of navigation">
							<span v-if="item.id != 198726" class="inClass" :class="{'nav-active': navActive == item.id}"
								@click="handleNavBtn(item)">{{item.name}}</span>
							<el-dropdown v-if="item.id == 198726">
								<span class="inClass" :class="{'nav-active': navActive == item.id}">
									解决方案<i class="el-icon-arrow-down"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<div class="fangan">
										<div class="left">
											<p @mouseover="handleChangActive(i.id)"
												:class="{'active': fanganActive == i.id}" v-for="i of item.childes"
												:key="i.id">
												{{i.name}}
												<span>></span>
											</p>
										</div>
										<div class="right">
											<p :class="{'active': active == j.id}" @click="handleClickGoBtn(j)"
												v-for="j of fanganArr" :key="j.id">
												{{j.name}}
											</p>
										</div>
									</div>
								</el-dropdown-menu>
							</el-dropdown>
						</span>


					</div>
				</div>
			</el-header>
			<div class="main">
				<router-view></router-view>
				<el-backtop></el-backtop>
			</div>
			<el-footer>
				<div class="footer-top">
					<div class="footUs" style="flex-grow: 2;">
						<div class="footTitle" v-for="item of footInfo" :key="item.id">
							<a href="">{{item.name}}</a>
							<div class="footText">
								<span @click="routeTo(i)" v-for="i of item.childes" :key="i.id">{{i.name}}</span>
							</div>
						</div>
					</div>
					<div class="about-us" style="margin: 0 83px;">
						<p class="title">关注我们</p>
						<img class="erweima" src="../assets/imgs/erweima.png" alt="">
					</div>
					<div class="about-us">
						<p class="title">联系我们</p>
						<div class="about-nav">
							<div>
								<p>邮箱：duan.liu@cklock.cn</p>
								<p>联系电话：400-066-1909</p>
								<p>地址：河北省承德市双滦区君泰财富广场A区</p>
							</div>
						</div>
					</div>
				</div>
				<div class="footer-bottom">
					<div class="copyright">
						<a href="https://beian.miit.gov.cn/#/Integrated/index"
							style="color: #fff; text-decoration: none;">版权所有&copy;天乾地坤科技有限股份公司&emsp;版权所有翼icp备17034267-1号</a>

					</div>
					<div class="footer-nav">
						<span class="nav-name">隐私保护</span>
						<span class="line"></span>
						<span class="nav-name">法律声明</span>
						<span class="line"></span>
						<span class="nav-name">网站地图</span>
					</div>
				</div>
			</el-footer>
		</el-container>
	</div>
</template>

<script>
	import { getDHpage } from "@/api/home";
	export default {
		name: 'Home',
		components: {

		},
		data() {
			return {
				navigation: [],
				footInfo: [],
				nav: [
					{
						id: '1',
						name: '数字城市',
						children: [
							{
								id: '2',
								name: '智慧家庭',
								url: '/wisdomFamily'
							},
							{
								id: '3',
								name: '智慧酒店',
								url: '/wisdomHotel'
							},
							{
								id: '4',
								name: '智慧安防',
								url: '/intelligentSecurity'
							},
							{
								id: '5',
								name: '智慧园区',
								url: '/wisdomPark'
							},
							{
								id: '6',
								name: '智慧物业',
								url: '/wisdomProperty'
							},
							{
								id: '7',
								name: '智慧公租房',
								url: '/publicHose'
							}
						]
					},
					{
						id: '8',
						name: '智慧教体',
						children: [
							{
								id: '9',
								name: '智慧校园',
								url: '/wisdomCampus'
							},
							{
								id: '10',
								name: '智慧党校',
								url: '/wisdomPartySchool'
							},
							{
								id: '11',
								name: '智慧体育公园',
								url: '/wisdomSportsPark'
							}
						]
					},
					{
						id: '12',
						name: '综合能源',
						children: [
							{
								id: '13',
								name: '综合能源',
								url: '/energy'
							}
						]
					},
					{
						id: '14',
						name: '城市更新',
						children: [
							{
								id: '15',
								name: '智慧管廊',
								url: '/wisdomNetwork'
							},
							{
								id: '16',
								name: '智慧路灯',
								url: '/wisdomStreetLamp'
							}
						]
					},
					{
						id: '17',
						name: '数字乡村',
						children: [
							{
								id: '18',
								name: '智慧大棚',
								url: '/wisdomGreenhouses'
							}
						]
					},
					{
						id: '21',
						name: '全要素疫情防控',
						children: [
							{
								id: '22',
								name: '全要素疫情防控',
								url: '/littleDoorGods'
							}
						]
					}
				],
				navActive: 198699,
				fanganActive: 198746,
				fanganArr: [],
				active: ''
			}
		},

		created() {

			// const userAgent = navigator.userAgent.toLowerCase();
			// if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(userAgent)) {
			// 	// 移动端
			// 	console.log('移动端')
			// 	window.location.href = 'https://www.cklock.cn/h5/#/pages/index/index';
			// } else {
			// 	// pc端
			// 	console.log('PC端')
			// }
			// this.navActive = this.$route.fullPath
			getDHpage().then(response => {
				const data = response.data
				this.navigation = data.navigation
				this.footInfo = data.footInfo
				localStorage.footInfo = JSON.stringify(data.footInfo)
				localStorage.navigation = JSON.stringify(data.navigation)
				console.log(this.footInfo, 'navigation')
				this.getFangan()
			})
		},

		methods: {
			routeTo(i) {
				console.log(i)
				this.navActive = i.parentId
				if (i.parentId == 198721) {
					this.$router.push('/wisdomFamily')
				}
				if (i.parentId == 198726) {
					this.$router.push({ path: '/energy', query: { id: i.id } }) //产品

				}
				if (i.parentId == 198754) {
					this.$router.push('/wisdomPartySchool')

				}
			},
			handleNavBtn(i) {
				console.log(i)
				// this.navActive = i
				// if (i == 198699) {
				// 	this.$router.push('/home')
				// }
				// if (i == 198751) {
				// 	this.$router.push('/mall')
				// }
				// if (i == 198753) {
				// 	this.$router.push('/wisdomCampus')
				// }
				// if (i == 198757) {
				// 	this.$router.push('/wisdomGreenhouses')
				// }
				// if (i == 198754) {
				// 	this.$router.push('/wisdomPartySchool')
				// }
				// if (i == 198721) {
				// 	this.$router.push('/wisdomFamily')
				// }
				this.navActive = i.id
				if (i.id == 198752) {
					window.open('https://tqdkjgs.cklock.cn/#/home')
				}
				this.$router.push(i.path)
				// if (i == 198756) {
				// 	this.$router.push('/cooperation')
				// }
				// if (i == 198758) {
				// 	this.$router.push('/about')
				// }
			},
			handleClick(val) {
				this.$router.push(val)
			},
			getFangan() {
				for (var item of this.navigation[2].childes) {
					if (item.id == this.fanganActive) {
						this.fanganArr = item.childes
					}
				}
			},
			handleChangActive(val) {
				this.fanganActive = val
				this.getFangan()
			},
			handleClickGoBtn(val) {
				console.log(val)
				this.navActive = 198726
				// if (val == 198747) {
				this.$router.push({ path: val.path, query: { id: val.id } }) //产品
				// }
				// this.$router.push(val)
			}
		},
		// watch: {
		// 	$route: {
		// 		handler: function (val, oldVal) {
		// 			this.navActive = val.fullPath;
		// 		},
		// 		// 深度观察监听
		// 		deep: true
		// 	}
		// },

	}
</script>
<style lang="scss" scoped>
	.footUs {
		display: flex;
		flex-direction: row
	}

	.container {
		display: flex;
		flex-direction: column;
		min-height: 60vh;
	}

	.footTitle {
		display: flex;
		flex-direction: column;
		font-size: 15px;
		font-weight: 600;
		color: #333333;
		min-width: 100px;

		a {
			font-size: 15px;
			font-weight: 600;
			color: #333333;
			text-decoration: none;
		}
	}

	.footText {
		margin-top: 24px;
		display: flex;

		flex-direction: column;

		span {
			font-size: 12px;
			font-weight: 400;
			margin-top: 10px;
		}
	}

	.home {
		/* width: 1200px; */
		min-height: 100vh;
		margin: 0 auto;
	}

	.el-header {
		height: 54px;
		display: flex;
		align-items: center;
		padding: 0 78px;
		justify-content: space-between;
		min-width: 1375px;
	}

	.logo {
		height: 40px;
		width: 150px;
		margin-right: 50px;
	}

	.search-img {
		width: 22px;
		height: 22px;
	}

	.nav-div {
		display: flex;
		font-size: 14px;
		font-weight: bold;
		color: #333333;
	}

	.nav {
		margin-right: 10px;

		&:last-child {
			margin-right: 0;
		}

	}

	.nav .inClass {
		display: inline-block;
		padding: 0 15px;
		height: 31px;
		line-height: 31px;
		text-align: center;
		cursor: pointer;
		white-space: nowrap;
		color: #333;
	}

	.nav .inClass:not(:last-child) {
		margin-right: 14px;
	}

	.nav .nav-active {
		background-color: #245ECB;
		color: #fff;
	}

	.search-btn {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.main {
		padding: 0;
	}

	.el-footer {
		height: 310px !important;
		padding: 0;
	}

	.footer-top {
		/* height: 260px; */
		/* line-height: 260px; */
		background-color: #F4F8FF;
		padding: 58px 99px 20px;
		display: flex;
		justify-content: space-between;
		box-sizing: border-box;

	}

	.footer-bottom {
		height: 50px;
		line-height: 50px;
		color: #fff;
		font-size: 9px;
		font-weight: 500;
		background-color: #333333;
		padding: 0 99px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.footer-nav span:not(:last-child) {
		margin-right: 17px;
	}

	.nav-name {
		cursor: pointer;
	}

	.line {
		display: inline-block;
		height: 8px;
		width: 1px;
		background-color: #fff;
	}

	.title {
		font-size: 15px;
		font-weight: 600;
		color: #333333;
		margin-bottom: 24px;
	}

	.about-nav {
		display: flex;
		justify-content: space-between;
	}

	.about-nav p {
		margin-bottom: 10px;
		font-size: 9px;
		font-weight: 600;
		color: #7E7E7E;
		cursor: pointer;
	}

	.about-nav div:not(:last-child) {
		/* margin-right: 60px; */
	}

	.erweima {
		width: 93px;
		height: 93px;
	}

	.fangan {
		height: 400px;
		display: flex;
	}

	.fangan div {
		width: 200px;
		padding: 20px;
	}

	.fangan p {
		height: 30px;
		line-height: 30px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
	}

	.fangan .left .active {
		color: #ff6700;
	}

	.fangan .right {
		margin-left: 50px;
	}

	.fangan .right p:hover {
		color: #ff6700;
		cursor: pointer;
	}

	.fangan .right .active {
		color: #ff6700;
	}
</style>